
export class ConnectAccount {
    id: string = "";
    email: string = "";
    externalAccounts: ExternalAccounts | null = null;
    requirements: Requirements | null = null;
  external_accounts: any;

    constructor(data?: { [field: string]: any }) {
        this.id = data?.id ?? "";
        this.email = data?.email ?? "";
        this.externalAccounts = data && data["external_accounts"] != null ? new ExternalAccounts(data["external_accounts"]) : null;
        this.requirements = data?.requirements != null ? new Requirements(data.requirements) : null;
    }

    toJson(): ConnectAccount {
        return Object.assign({}, this);
    }
}


export class ExternalAccounts {
    data: Data[];

    constructor(data?: { [field: string]: any }) {
        if (data?.data) {
            this.data = [];
            data.data.forEach((d: any) => {
                this.data.push(new Data(d));
            });
        } else {
            this.data = [];
        }
    }

    toJson(): ExternalAccounts {
        return Object.assign({}, this);
    }

}

export class Data {
    id: string;
    bankName: string = "";
    last4: string = "";
    routingNumber: string = "";
    status: 'new' | 'validated' | 'verified' | 'verification_failed' | 'errored';

    constructor(data?: { [field: string]: any }) {
        this.id = data?.id ?? "";
        //@ts-ignore
        this.bankName = data['bank_name'] ?? "";
        this.last4 = data?.last4 ?? "";
        this.routingNumber = data?.routingNumber ?? "";
        this.status = data?.status ?? "";
    }

    toJson(): Data {
        return Object.assign({}, this);
    }
}


class Requirements {
    currentlyDue: string[] = [];
    disabledReason: string = "";
    eventuallyDue: string[] = [];
    pastDue: string[] = [];
    pendingVerification: string[] = [];
  currently_due: any;

    constructor(data?: { [field: string]: any }) {
        //@ts-ignore
        this.currentlyDue = data['currently_due'] ?? [];
        //@ts-ignore
        this.disabledReason = data['disabled_reason'] ?? "";
        //@ts-ignore
        this.eventuallyDue = data['eventually_due'] ?? [];
        //@ts-ignore
        this.pastDue = data['past_due'] ?? [];
        //@ts-ignore
        this.pendingVerification = data['pending_verification'] ?? [];
    }

    toJson(): Requirements {
        return Object.assign({}, this);
    }
}