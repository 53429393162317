import React, { useState, useEffect } from 'react';
import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CustomInput from '../../../../components/inputs/TextField';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from 'react-places-autocomplete';
import Address from '../../../../models/[new]address';

const searchOptions = {
  types: ['address'],
  // componentRestrictions: { country: 'us' },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionsCard: {
      position: 'relative',
    },
    option: {
      cursor: 'pointer',
      width: '100%',
      '&:hover': {
        background: '#ddd',
      },
      marginBottom: '0.25rem',
    },
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
    },
    mainText: {
      fontWeight: 'bold',
    },
  }),
);
interface Props {
  initialValue?: any;
  getValues: (args: Omit<Address, 'addressLineTwo' | 'id' | 'name'>) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
}

const AddressPickerWithGoogle = ({
  getValues,
  initialValue,
  setFieldValue,
}: Props) => {
  const classes = useStyles();
  const [googleAddress, setGoogleAddress] = useState('');

  useEffect(() => {
    if (initialValue !== '') {
      geocodeByPlaceId(initialValue)
        // .then(r => setGoogleAddress(r[0]?.formatted_address))
        .catch(() => setGoogleAddress(initialValue));
    } else {
      setGoogleAddress('');
    }
  }, [initialValue]);

  const [showOptions, setShowOptions] = useState(false);

  // https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
  const handleSelect = async (address: any) => {
    setShowOptions(false);

    setGoogleAddress(address);

    let addressLineOne = '';
    let country = '';
    let state = '';
    let suite = '';
    let city = '';
    let zipCode = '';

    const geoCode = await geocodeByAddress(address);

    const { lat, lng } = await getLatLng(geoCode[0]);

    const placeId = geoCode[0]?.place_id;

    geoCode[0]['address_components']?.forEach(addressComponent => {
      const { types } = addressComponent;
      if (types?.includes('country')) {
        country = addressComponent['short_name'];
      }
      if (types?.includes('street_number')) {
        addressLineOne += addressComponent['short_name'];
      }
      if (types?.includes('route')) {
        addressLineOne += ' ' + addressComponent['short_name'];
      }

      if (types?.includes('postal_code')) {
        zipCode = addressComponent['short_name'];
      }
      if (types?.includes('administrative_area_level_1')) {
        state = addressComponent['short_name'];
      }
      if (
        types?.includes('sublocality_level_1') ||
        types?.includes('administrative_area_level_3') ||
        types?.includes('locality') ||
        types?.includes('sublocality_level_1')
      ) {
        city = addressComponent['short_name'];
      }
    });
    getValues({
      addressLineOne,
      country,
      state,
      suite,
      city,
      zipCode,
      placeId,
      lat,
      lng,
    });
  };

  return (
    <PlacesAutocomplete
      searchOptions={searchOptions}
      value={googleAddress}
      onChange={value => {
        if (value) setShowOptions(true);
        else setShowOptions(false);
        setGoogleAddress(value);
      }}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div>
          <CustomInput
            fullWidth
            label="Search Address"
            aria-label="search-address"
            variant="outlined"
            name="googleSearchField"
            required
            {...getInputProps({ placeholder: 'type to search' })}
            onBlur={() => {
              setShowOptions(false);
            }}
          />
          {showOptions && (
            <Card id="search-address" className={classes.optionsCard}>
              <CardContent>
                {suggestions?.length === 0 ? (
                  <div>loading ...</div>
                ) : (
                  suggestions?.map((s, index) => {
                    return (
                      <div
                        {...getSuggestionItemProps(s)}
                        key={index}
                        className={classes.option}
                      >
                        <Grid container alignItems="center">
                          <Grid item>
                            <LocationOnIcon className={classes.icon} />
                          </Grid>
                          <Grid item xs>
                            <Typography
                              variant="body1"
                              color="textPrimary"
                              className={classes.mainText}
                            >
                              {s?.formattedSuggestion?.mainText}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {s?.formattedSuggestion?.secondaryText}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  })
                )}
              </CardContent>
            </Card>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default AddressPickerWithGoogle;
