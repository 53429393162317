import firebase from 'firebase/app';

import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/messaging';

let firebaseConfig;
export let baseURL: string;

if (process.env.REACT_APP_ENV === 'production') {
  baseURL = 'https://us-central1-antiquesmart-709cd.cloudfunctions.net';

  firebaseConfig = {
    apiKey: 'AIzaSyC2LluMWnQhh7Mu31TZk5JmbLt9B2grtgw',
    authDomain: 'antiquesmart-709cd.firebaseapp.com',
    databaseURL: 'https://antiquesmart-709cd.firebaseio.com',
    projectId: 'antiquesmart-709cd',
    storageBucket: 'antiquesmart-709cd.appspot.com',
    messagingSenderId: '376684689352',
    appId: '1:376684689352:web:17bac8f3933af73d24b2fd',
    measurementId: 'G-5LH5KBE149',
  };
} else {
  // baseURL = 'https://us-central1-antiqusmart.cloudfunctions.net';
  baseURL = "https://us-central1-antiqusmart.cloudfunctions.net";
  firebaseConfig = {
    apiKey: 'AIzaSyBNLK6vMMagQ_LsrxqGCcVvzWO4sJb09cY',
    authDomain: 'antiqusmart.firebaseapp.com',
    projectId: 'antiqusmart',
    storageBucket: 'antiqusmart.appspot.com',
    messagingSenderId: '171223945164',
    appId: '1:171223945164:web:2f148899c40c292dcd1769',
    measurementId: 'G-9D52J67YH4',
  };
}

// development keys

// const firebaseConfig = {
//   apiKey: 'AIzaSyBNLK6vMMagQ_LsrxqGCcVvzWO4sJb09cY',
//   authDomain: 'antiqusmart.firebaseapp.com',
//   projectId: 'antiqusmart',
//   storageBucket: 'antiqusmart.appspot.com',
//   messagingSenderId: '171223945164',
//   appId: '1:171223945164:web:2f148899c40c292dcd1769',
//   measurementId: 'G-9D52J67YH4',
// };

// export const baseURL = 'https://us-central1-antiqusmart.cloudfunctions.net';

//productions keys
// export const baseURL =
//   'https://us-central1-antiquesmart-709cd.cloudfunctions.net';
// const firebaseConfig = {
//   apiKey: 'AIzaSyC2LluMWnQhh7Mu31TZk5JmbLt9B2grtgw',
//   authDomain: 'antiquesmart-709cd.firebaseapp.com',
//   databaseURL: 'https://antiquesmart-709cd.firebaseio.com',
//   projectId: 'antiquesmart-709cd',
//   storageBucket: 'antiquesmart-709cd.appspot.com',
//   messagingSenderId: '376684689352',
//   appId: '1:376684689352:web:17bac8f3933af73d24b2fd',
//   measurementId: 'G-5LH5KBE149',
// };

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
firestore.settings({ ignoreUndefinedProperties: true });
export const storage = firebase.storage();
//export const messagingFCM= ()=>firebase.messaging().getToken({vapidKey:"BJoWrJsF2wxhPoadM3nrlVI7NRJ94h7KveFtPKAyEEl5-Im0s_MaRgRe9B8p18K86Rabpguf67GQs59a_gAPrwI"});

export const profilePicturesStoragePath = '/profileImages';
export const itemsAttachmentStoragePath = '/ItemAttachments';
export const addEventStoragePath = '/events';

export const chatAttachments = (uid: string) =>
  firebase.storage().ref(`/chatAttachments/${uid}`);

export const SignInWithGoogle = () => {
  const GoogleProvider = new firebase.auth.GoogleAuthProvider();
  return auth.signInWithPopup(GoogleProvider);
};

export const SignInWithFacebook = () => {
  const FacebookProvider = new firebase.auth.FacebookAuthProvider();
  return auth.signInWithPopup(FacebookProvider);
};

export const SignInWithApple = () => {
  const AppleProvider = new firebase.auth.OAuthProvider('apple.com');
  AppleProvider.addScope('email');
  AppleProvider.addScope('name');
  const result = auth.signInWithPopup(AppleProvider);
  return result;
};

export const forgetUser = () =>
  auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);

export const remeberUser = () =>
  auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export const generateUserToken = async () =>
  (await auth.currentUser?.getIdToken(false)) ?? '';

export const users = firebase.firestore().collection('users');
export const privateUsers = firebase.firestore().collection('private_users');
export const products = firebase.firestore().collection('Products');
export const usersC = firestore.collection('users');
export const privateUsersC = firestore.collection('private_users');
export const featuredC = firestore.collection('featured');
export const categoriesC = firestore.collection('categories');
export const messagingC = firestore.collection('messaging');
export const postsC = firestore.collection('forumPosts');

export const commentsC = (postID: string | undefined) =>
  firestore.collection('forumPosts').doc(postID).collection('comments');

export const repliesC = (
  postID: string | undefined,
  commentID: string | undefined,
) =>
  firestore
    .collection('forumPosts')
    .doc(postID)
    .collection('comments')
    .doc(commentID)
    .collection('replies');

export const privateUserAddressesC = (uid: string | undefined) =>
  firestore.collection('private_users').doc(uid).collection('addresses');

export const privateUserSourcesC = (uid: string) =>
  firestore.collection('private_users').doc(uid).collection('sources');

export const userPaypalAccount = (uid: string) =>
  firestore
    .collection('private_users')
    .doc(uid)
    .collection('paypal')
    .doc('account'); // currently one account per user

export const userNamesC = firestore.collection('userNames');
export const productsC = firestore.collection('Products');
export const fcmTokensC = firestore.collection('fcmTokens');

export const pushFcmToken = async ({
  uid,
  token,
}: {
  uid: string;
  token: string;
}) => {
  await fcmTokensC.doc(uid).set(
    {
      tokens: firebase.firestore.FieldValue.arrayUnion(token),
    },
    { merge: true },
  );
};

export const popFcmToken = async ({
  uid,
  token,
}: {
  uid: string;
  token: string;
}) => {
  await fcmTokensC.doc(uid).set(
    {
      tokens: firebase.firestore.FieldValue.arrayRemove(token),
    },
    { merge: true },
  );
};

export const reauthUser = (password: string) => {
  const currentUser = auth.currentUser;
  const cred = firebase.auth.EmailAuthProvider.credential(
    currentUser?.email as string,
    password,
  );
  return currentUser?.reauthenticateWithCredential(cred);
};

// export const userAddressesC = (userId: string) =>
//   firestore.collection('private_users').doc(userId).collection('addresses');

// export const userSourcesC = (userId: string) =>
//   firestore.collection('users').doc(userId).collection('sources');

// export const userPaypalAccount = (userId: string) =>
// firestore.collection('users').doc(userId).collection('sources');

export const ordersC = firestore.collection('orders');

export const orderPrivateInfoC = (orderId: string) =>
  firestore.collection('orders').doc(orderId).collection('private_info');

export const orderPrivatePaypalInfoC = (orderId: string) =>
  firestore.collection('orders').doc(orderId).collection('private_paypal_info');

// //////////////////////// legacy code ///////////////////////////////////////

export const Logout = () => auth.signOut();
export const RegisterWithEmailAndPassword = (email: string, password: string) =>
  auth.signInWithEmailAndPassword(email, password);
export default firebase;
