import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { auth, baseURL } from '../../../utils/firebase-utils';
import {
  setCheckoutData,
  setCheckoutError,
  setCheckoutLoading,
} from '../../../redux/actions/checkoutActions';

export const CheckoutProcessing = (uid: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setCheckoutLoading(true));
      const currentUser = auth?.currentUser;
      if (!currentUser) throw new Error('User is not authenticated');

      const userToken = await currentUser?.getIdToken();
      const url = `${baseURL}/createPaymentLink`;

      const headers = {
        'Content-Type': 'application/json',
        authorization: userToken,
      };
      const body = JSON.stringify({ uid });
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body,
      });

      if (!response.ok) {
        throw new Error('Failed to calculate checkout');
      }

      const responseData = await response.json();
      dispatch(setCheckoutData(responseData));
      dispatch(setCheckoutError(null));
    } catch (error) {
      dispatch(setCheckoutError('Error calculating checkout'));
      console.error('Error calculating checkout:', error);
    } finally {
      dispatch(setCheckoutLoading(false));
    }
  };
};
