import React from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  useTheme,
  Grid,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '90vh',
      padding: '7rem 4rem',
      [theme.breakpoints.down('md')]: {
        padding: '1rem 0rem',
      },
    },
    contentWrapper: {
      minHeight: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
      paddingBottom: '3%',
    },
    leftSide: {
      paddingBottom: '%',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        textAlign: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    rightSide: {
      paddingBottom: '8%',

      display: 'flex',
      [theme.breakpoints.down('md')]: {
        paddingBottom: '2rem',
      },
    },
    textWrapper: {},
    categoriesBtn: {
      height: '70px',
      fontFamily: "'Poppins',sans-serif",
      color: theme.palette.secondary.main,
      background: '#fff',
      textTransform: 'initial',
      fontSize: '20px',
    },
    textWrapper_title: {
      fontWeight: 'bold',
      color: '#000',
      fontFamily: "'Poppins',sans-serif",
      [theme.breakpoints.down('md')]: {},
    },
    textWrapper_desc: {
      color: '#fff',
      fontFamily: "'Poppins',sans-serif",
      marginBottom: '1rem',
    },
    bestSelligCard: {
      marginRight: '1.25rem',
    },
    appsContainer: {
      minHeight: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    linksContainer: {
      paddingTop: '10%',
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '0 2rem',
        display: 'flex',
        textAlign: 'left',
        flexDirection: 'column',
        alignItems: 'center',
        order: '2',
      },
    },
    linksImages: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: '3rem',
    },
  }),
);

const Navigate = () => {
  const classes = useStyles();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <section style={{ margin:'30px 0 0 0' }}>
      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid item xs={12}
        //  sm={11} md={11} lg={12}
         >
          <div
            style={{
              position: 'relative',
              // borderRadius: '10px',
              background: xsDown? '#b77954' : "none",
              height:  xsDown? '100vh' : '60vh',
            }}
          >
            {!xsDown && (
              <img
                src={require('../../assets/landing-page/SectionLandingPage.png')}
                alt="Background"
                style={{
                  width: '100%',
                  height: xsDown ? '100vh' : '60vh',
                  // borderRadius: '10px',
                  position: 'relative',
                }}
              />
            )}
            <div
              style={{
                position: 'absolute',
                top: xsDown ? '30px' : '25px',
                left: 0,
                width: xsDown? '100%' : "80%",
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                zIndex: 2,
                textAlign: 'center',
                padding: xsDown ? '20px' : '50px',
              }}
            >
              <Typography
                component="p"
                variant="h3"
                // gutterBottom
                style={{
                  fontSize: xsDown ? '38px' : '42px',
                  fontWeight: 700,
                  lineHeight: '56.2px',
                  textAlign: 'center',
                  color: '#FFFFFF',
                }}
              >
                Download the Antiquesmart app for iPhone and Android
              </Typography>
              <div
                className={classes.linksImages}
                style={{
                  flexDirection: xsDown ? 'column' : 'row',
                }}
              >
                <a
                  href="https://play.google.com/store/apps/details?id=com.zetaton.antiquesmart"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    width="180px"
                    src={require('../../assets/google-play-badge.png')}
                    alt="get on google play"
                    style={{
                      transform: xsDown ? 'scale(1.13)' : 'scale(1.13)',
                      marginRight: xsDown ? '0rem' : '1rem',
                    }}
                    loading="lazy"
                  />
                </a>
                <a
                  href="https://apps.apple.com/us/app/antiquesmart/id1526581604?platform=iphone"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    width="180px"
                    src={require('../../assets/app-store-png-logo-33107.png')}
                    alt="get on app store"
                    style={{ marginTop: xsDown ? '-55px' : '-55px' }}
                    loading="lazy"
                  />
                </a>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default Navigate;
