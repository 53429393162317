import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Container,
  useTheme,
  Grid,
  alpha,
  Box,
  CircularProgress,
  useMediaQuery,
} from '@material-ui/core';

import { Prompt } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import { useSnackbar, OptionsObject as SnackbarOptions } from 'notistack';

import CustomButton from '../../../../components/buttons/Button';
import { addEventStoragePath } from '../../../../utils/firebase-utils';
import { useTypedSelector } from '../../../../redux/store';
import firebase from './../../../../utils/firebase-utils';
import { RiMailAddFill } from 'react-icons/ri';
import { MdDelete } from 'react-icons/md';
import Address from '../../../../models/[new]address';
import { formatAddress } from '../../../../utils/address';
import AddressPickerWithGoogle from '../../../ProfileSetting/ProfileSetttingForm/ShopInfoForm/ShopLocation';
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { enUS } from 'date-fns/locale';
import {
  format,
  isAfter,
  isValid,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';

const combineDateAndTime = (date: Date | null, time: any): Date | null => {
  if (!date || !time) {
    console.error('Invalid date or time:', { date, time });
    return null;
  }

  if (time._isAMomentObject && typeof time.toDate === 'function') {
    time = time.toDate();
  }

  if (!isValid(date) || !isValid(time)) {
    console.error('Invalid date or time after conversion:', { date, time });
    return null;
  }

  const combinedDate = setHours(
    setMinutes(setSeconds(date, time.getSeconds()), time.getMinutes()),
    time.getHours(),
  );

  return combinedDate;
};

const snackbarCommonOpt: SnackbarOptions = {
  autoHideDuration: 3000,
  preventDuplicate: true,
  anchorOrigin: { horizontal: 'right', vertical: 'top' },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '70%',
      margin: '3rem auto',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    pageLoader: {
      marginTop: '5rem',
    },

    scrollContainer: {
      '&::-webkit-scrollbar': {
        width: '6px !important',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D28B53 !important',
        borderRadius: '4px !important',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#F3F3F3 !important',
        borderRadius: '4px !important',
      },
    },

    commonInputStyle: {
      width: '100%',
      padding: '13px',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '21px',
      letterSpacing: '-0.02em',
      textAlign: 'left',
      color: '#141414',
      border: '1px solid #8080807d',
      borderRadius: '5px',
    },
    noBorder: {
      width: '100%',
      padding: '9px 13px',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '21px',
      letterSpacing: '-0.02em',
      textAlign: 'left',
      color: '#141414',
      border: '1px solid #8080807d',
      borderRadius: '5px',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
      },
      '& .MuiInput-underline:after': {
        borderBottom: 'none !important',
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none !important',
      },
      '& .MuiInput-underline:before': {
        borderBottom: 'none !important',
      },
    },
    common: {
      width: '100%',
      padding: '13px',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '21px',
      letterSpacing: '-0.02em',
      textAlign: 'left',
      borderRadius: '10px',
      marginTop: '8px',
    },
    label: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '21px',
      letterSpacing: '-0.02em',
      textAlign: 'left',
      color: '#1A202C',
      display: 'block',
      marginBottom: '10px',
    },
    subTitle: {
      fontSize: '.7rem',
      color: '#7A869A',
      margin: '5px 20px 20px 20px',
      fontWeight: 500,
      lineHeight: '1rem',
      fontStyle: 'italic',
      fontFamily: 'Poppins',
    },
    btn: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '22.4px',
      textAlign: 'center',
      padding: '13px 40px',
      borderRadius: '5px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '170px',
      height: '50px',
      color: theme.palette.primary.light,
      backgroundColor: '#D28B53',
      border: '1px solid #D28B53',
    },
    focusBlur: {
      outline: 'none',
      border: '1px solid #8080807d',
    },
    inputColor: {
      color: '#141414',
      background: theme.palette.primary.light,
    },
    imgsContainer: {
      display: 'flex',
      justifyContent: 'center',
      margin: '.1rem 0 ',
    },
    commentImg: {
      width: '100px',
      height: '100px',
      marginRight: '10px',
      borderRadius: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '55px',
        height: '55px',
      },
    },
    btnsWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '1rem 2rem',
    },
    imgBtn: {
      width: '10.8125rem',
      height: '2.125rem',
      color: '#898989',
      fontFamily: 'Poppins',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      borderRadius: '.25rem',
      border: '1px solid #EAEAEA',
    },
    postBtn: {
      width: '5rem',
      height: '2.125rem',
      fontSize: '0.75rem',
      fontFamily: 'Poppins',
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '2rem 0',
    },
    txt: {
      fontWeight: 'bold',
      fontSize: '1.75rem',
      fontFamily: 'Arial',
      marginLeft: '1rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.4rem',
      },
    },
    brownDiv: {
      width: '0.375rem',
      height: '1.75rem',
      borderRadius: '0.625rem',
      background: '#D28B53',
    },

    fitContentHeight: {
      flexDirection: 'column',
      margin: 'auto',
    },
    descriptionField: {
      width: '100%',
      whiteSpace: 'pre-line',
      border: 'none',
    },
    postImageFeaturedStar: {
      position: 'absolute',
      top: -15,
      right: -10,
      color: '#B87A4E',
      opacity: 1,
    },
    form: {
      display: 'contents',
    },
    input: {
      backgroundColor: '#F9F9F9',
      borderRadius: '0.3125rem 0rem 0rem 0.3125rem',
      padding: theme.spacing(1, 1, 1, 1),
      transition: theme.transitions.create('width'),
      height: '35px',
      border: '1px solid #E6E6E6;',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchBtn: {
      borderRadius: '0rem 0.3125rem 0.3125rem 0rem',
      backgroundColor: '#FDBB84',
      height: '35px',
    },
    no_results: {
      fontSize: '24px',
      color: '#CF8A50',
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: '20px',
    },
  }),
);

interface Props {
  className?: string;
}

type ImageObject = {
  file: File | null;
  url: string;
};

const ListEvent: React.FC<Props> = ({ className }) => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const isEditMode = Boolean(id);

  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isDirty, setIsDirty] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState<ImageObject[]>([]);
  const {
    userName,
    profilePicture,
    uid,
    userType,
    firstName,
    lastName,
    shop,
    sellerData,
  } = useTypedSelector(state => state.user);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [eventData, setEventData] = useState<any>(null);

  const [googleAddress, setGoogleAddress] = useState<Omit<
    Address,
    'id' | 'name'
  > | null>(null);

  const eventSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Title is required')
      .min(3, 'Title must be at least 3 characters')
      .test('max-words', 'Title must not exceed 30 words', value => {
        if (!value) return true;
        return value.split(' ').filter(word => word.length > 0).length <= 20;
      }),
    description: Yup.string()
      .trim()
      .required('Description is required')
      .min(15, 'Description must be at least 15 characters'),
    images: Yup.array()
      .min(1, 'Please upload at least one image')
      .required('Photos are required'),

    eventDate: Yup.date()

      .nullable()
      .required('Event date is required')
      .typeError('Please select a valid date')
      .when([], {
        is: () => !isEditMode,
        then: schema =>
          schema.min(new Date(), 'Please select a valid future date'),
        otherwise: schema => schema,
      }),

    startTime: Yup.date().required('Start Time is required').nullable(),
    endTime: Yup.date()
      .required('End Time is required')
      .nullable()
      .test(
        'is-greater',
        'End Time must be after Start Time',
        function (value) {
          const { startTime } = this.parent;
          return value && startTime ? isAfter(value, startTime) : true;
        },
      ),
    price: Yup.number()
      .typeError('Price must be a valid number')
      .min(0, 'Price must be at least 0')
      .required('Price is required'),
  });

  const dynamicUserName = (() => {
    if (userType === 'buyer') {
      return `${firstName} ${lastName}`;
    } else if (userType === 'shop') {
      return shop?.shopName;
    } else if (userType === 'seller') {
      return sellerData?.sellerName;
    } else {
      return userName || '';
    }
  })();

  useEffect(() => {
    const fetchEventData = async () => {
      setIsLoading(true);
      try {
        const doc = await firebase
          .firestore()
          .collection('events')
          .doc(id)
          .get();
        if (doc.exists) {
          setEventData(doc.data());
        } else {
          console.error('Event not found');
        }
      } catch (error) {
        console.error('Error fetching event data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) fetchEventData();
  }, [id]);

  useEffect(() => {
    if (eventData) {
      setSelectedImages(
        eventData?.images?.map((url: string) => ({ url })) || [],
      );
      setGoogleAddress(eventData?.address?.addressLineOne || null);
    }
  }, [eventData]);

  const uploadImage = async (file: File, path: string): Promise<string> => {
    const storageRef = firebase.storage().ref(path);
    try {
      setIsLoading(true);
      const snapshot = await storageRef.put(file);
      const downloadURL = await snapshot.ref.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    } finally {
      setTimeout(() => setIsLoading(false), 2000);
    }
  };

  const handleImageUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void,
  ) => {
    const files = Array.from(e?.target?.files || []).filter(file =>
      file.type.startsWith('image/'),
    );
    if (files.length + selectedImages.length > 10) {
      enqueueSnackbar('You can upload a maximum of 10 images.', {
        variant: 'error',
        ...snackbarCommonOpt,
      });

      return;
    }

    const uploadedImages = await Promise.all(
      files?.map(async file => {
        const cleanFileName = file?.name.replace(/(\.\.\/|\/|\.\.)/g, '');
        const imageName = `image-${format(
          new Date(),
          'MMMM dd, yyyy',
        )}-${cleanFileName}`;
        const path = `${addEventStoragePath}/${uid}/${imageName}`;
        const url = await uploadImage(file, path);
        return { file: null, url };
      }),
    );

    const updatedImages = [...selectedImages, ...uploadedImages];

    setSelectedImages(updatedImages);
    setIsDirty(true);
    setFieldValue(
      'images',
      updatedImages.map(img => img.url),
    );

    if (e.target) {
      e.target.value = '';
    }
  };

  const handleOnDragEnd = (
    result: DropResult,
    setFieldValue: (field: string, value: any) => void,
  ) => {
    if (!result.destination) return;
    const reorderedImages = Array.from(selectedImages);
    const [movedImage] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, movedImage);
    setSelectedImages(reorderedImages);
    setFieldValue(
      'images',
      reorderedImages.map(img => img.file),
    );
  };

  const deleteImageFromStorage = async (imageUrl: string) => {
    try {
      const storageRef = firebase.storage().refFromURL(imageUrl);
      await storageRef.delete();
      enqueueSnackbar('Image deleted successfully.', {
        variant: 'success',
        ...snackbarCommonOpt,
      });
    } catch (error) {
      enqueueSnackbar('Error deleting image.', {
        variant: 'error',
        ...snackbarCommonOpt,
      });
      console.error('Error deleting image from Firebase Storage:', error);
    }
  };

  const handleRemoveImage = async (
    index: number,
    setFieldValue: (field: string, value: any) => void,
  ) => {
    const updatedImages = [...selectedImages];
    const removedImage = updatedImages.splice(index, 1)[0];

    if (removedImage?.url) {
      await deleteImageFromStorage(removedImage.url);

      URL.revokeObjectURL(removedImage.url);
    }
    setSelectedImages(updatedImages);

    setFieldValue(
      'images',
      updatedImages.map(img => img.file),
    );
  };

  useEffect(() => {
    if (eventData && eventData?.address) {
      setGoogleAddress(eventData?.address);
    }
  }, [eventData]);

  const preventMinus = (e: KeyboardEvent) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  const preventPasteNegative = (e: ClipboardEvent) => {
    const clipboardData = e.clipboardData;
    if (clipboardData) {
      const pastedData = parseFloat(clipboardData.getData('text') || '0');
      if (pastedData < 0) {
        e.preventDefault();
      }
    }
  };

  const handleSubmit = async (
    values: typeof initialFormData,
    { resetForm }: FormikHelpers<typeof initialFormData>,
  ) => {
    const eventDate = values?.eventDate ? new Date(values.eventDate) : null;

    const combinedEventDate = combineDateAndTime(
      // values?.eventDate,
      eventDate,
      values?.startTime,
    );
    const combinedEndDate = combineDateAndTime(
      // values?.eventDate,
      eventDate,
      values?.endTime,
    );

    if (!combinedEventDate || !combinedEndDate) {
      console.error('Invalid date/time combination');
      return;
    }
    setIsDirty(false);
    setIsSaveLoading(true);
    try {
      const eventRef = id
        ? firebase.firestore().collection('events').doc(id)
        : firebase.firestore().collection('events').doc();

      const updatedEvent = {
        title: values?.name,
        content: values?.description,
        address: googleAddress,
        uid: uid,
        userName: dynamicUserName || '',
        profilePicture:
          userType === 'shop' ? shop?.shopLogo : profilePicture?.url || '',
        type: 'normalEvent',
        images: selectedImages?.map(img => img.url),
        event_date: firebase.firestore.Timestamp.fromDate(combinedEventDate),
        event_end_date: firebase.firestore.Timestamp.fromDate(combinedEndDate),
        eventCost: values?.price,
      };

      if (id) {
        // Update existing event
        await eventRef.update({
          ...updatedEvent,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
        enqueueSnackbar('Event updated successfully!', {
          variant: 'success',
          ...snackbarCommonOpt,
        });
      } else {
        // Create new event
        await eventRef.set({
          ...updatedEvent,
          id: eventRef.id,

          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
        enqueueSnackbar('Event created successfully!', {
          variant: 'success',
          ...snackbarCommonOpt,
        });
      }

      resetForm();
      setSelectedImages([]);
      setGoogleAddress(null);
      history.push('/events');
    } catch (error) {
      console.error('Error creating event:', error);
      enqueueSnackbar('Failed to create event. Please try again.', {
        variant: 'error',
        ...snackbarCommonOpt,
      });
    } finally {
      setTimeout(() => setIsSaveLoading(false), 2000);
    }
  };

  const convertTimestampToDate = (timestamp: { seconds: number }): Date => {
    return new Date(timestamp.seconds * 1000);
  };

  const initialFormData = {
    name: eventData?.title || '',
    description: eventData?.content || '',
    eventDate: eventData?.event_date
      ? convertTimestampToDate(eventData?.event_date)
      : null,
    startTime: eventData?.event_date
      ? convertTimestampToDate(eventData?.event_date)
      : null,
    endTime: eventData?.event_end_date
      ? convertTimestampToDate(eventData?.event_end_date)
      : null,
    price: eventData?.eventCost ?? '',

    location: eventData?.address?.addressLineOne || '',
    images: eventData?.images || [],
  };

  return (
    <Container>
      <Grid className={clsx(classes.titleContainer)}>
        <span className={clsx(classes.brownDiv)}></span>
        <h1 className={clsx(classes.txt)}>
          {id ? 'Edit Event' : 'Schedule Event'}
        </h1>
      </Grid>

      <Formik
        enableReinitialize
        initialValues={initialFormData}
        validationSchema={eventSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          values,
          setFieldValue,
          setFieldTouched,
          setErrors,
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginBottom: '10px' }}>
                <label className={clsx(classes.label)}>Enter Name *</label>
                <Field
                  name="name"
                  placeholder="Enter event name"
                  className={classes.commonInputStyle}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setIsDirty(true);
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/\s+/g, ' ');

                    const formattedValue = inputValue
                      .replace(/(?:^|\.\s+)(\w)/g, match => match.toUpperCase())
                      .replace(/^\w/, match => match.toUpperCase());

                    setFieldValue('name', formattedValue);
                  }}
                  onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                    e.target.style.outline = 'none';
                    e.target.style.border = '1px solid #8080807d';
                  }}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    e.target.style.outline = 'none';
                    e.target.style.border = '1px solid #8080807d';
                  }}
                />
                {errors.name && touched.name ? (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '14px',
                      padding: '4px 10px',
                      fontFamily: 'Poppins',
                    }}
                  >
                    {errors.name}
                  </div>
                ) : null}
              </Grid>
              {/** date */}
              <Grid item xs={12} style={{ marginBottom: '10px' }}>
                <label className={clsx(classes.label)}>Event Date *</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enUS}>
                  <DatePicker
                    value={values?.eventDate || null}
                    onChange={date => {
                      setFieldValue('eventDate', date);
                      setIsDirty(true);
                    }}
                    disablePast={!isEditMode}
                    format={values?.eventDate ? 'MMMM dd, yyyy' : ''}
                    // disablePast
                    fullWidth
                    className={classes.noBorder}
                    placeholder="Select Event Date"
                    onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                      e.target.style.outline = 'none';
                      e.target.style.border = 'none';
                    }}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                      e.target.style.outline = 'none';
                      e.target.style.border = 'none';
                    }}
                  />
                </MuiPickersUtilsProvider>
                {errors?.eventDate && touched?.eventDate ? (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '14px',
                      padding: '4px 10px',
                      fontFamily: 'Poppins',
                    }}
                  >
                    {errors.eventDate}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={6} style={{ marginBottom: '10px' }}>
                <label className={clsx(classes.label)}>Start Time *</label>
                <TimePicker
                  value={values?.startTime}
                  onChange={time => {
                    setFieldValue('startTime', time);
                    setIsDirty(true);
                  }}
                  placeholder="Select time"
                  minutesStep={5}
                  format="hh:mm a"
                  fullWidth
                  className={classes.noBorder}
                />
                {errors.startTime && touched.startTime ? (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '14px',
                      padding: '4px 10px',
                      fontFamily: 'Poppins',
                    }}
                  >
                    {errors.startTime}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={6} style={{ marginBottom: '10px' }}>
                <label className={clsx(classes.label)}>End Time *</label>
                <TimePicker
                  value={values?.endTime}
                  onChange={time => {
                    setFieldValue('endTime', time);
                    setIsDirty(true);
                  }}
                  placeholder="Select time"
                  minutesStep={5}
                  format="hh:mm a"
                  fullWidth
                  className={classes.noBorder}
                  disabled={!values.startTime}
                />
                {errors.endTime && touched.endTime ? (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '14px',
                      padding: '4px 10px',
                      fontFamily: 'Poppins',
                    }}
                  >
                    {errors.endTime}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} style={{ marginBottom: '10px' }}>
                <label className={clsx(classes.label)}>Description *</label>
                <Field
                  as="textarea"
                  name="description"
                  placeholder="Please provide a description of your event"
                  className={classes.commonInputStyle}
                  style={{ height: '150px' }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setIsDirty(true);
                    let inputValue = e.target.value;

                    // Trim extra spaces but keep line breaks
                    const trimmedValue = inputValue.replace(/ {2,}/g, ' ');

                    // Capitalize the first letter after a period
                    const capitalizedValue = trimmedValue.replace(
                      /(?:^|\.\s+)(\w)/g,
                      (match, p1) => {
                        return match.replace(p1, p1.toUpperCase());
                      },
                    );

                    // Ensure the user cannot start a new line without typing something first
                    const lines = capitalizedValue.split('\n');
                    const validatedLines = lines.filter((line, index) => {
                      // Allow new lines only if there is at least one non-empty line before it
                      if (index === 0 || lines[index - 1].trim().length > 0) {
                        return true;
                      }
                      return line.trim().length > 0; // Allow non-empty lines
                    });

                    const cleanedValue = validatedLines.join('\n');

                    setFieldValue('description', cleanedValue);
                  }}
                  onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                    e.target.style.outline = 'none';
                    e.target.style.border = '1px solid #8080807d';
                  }}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    e.target.style.outline = 'none';
                    e.target.style.border = '1px solid #8080807d';
                  }}
                />
                {errors.description && touched.description ? (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '14px',
                      padding: '4px 10px',
                      fontFamily: 'Poppins',
                    }}
                  >
                    {errors.description}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} style={{ marginBottom: '10px' }}>
                <label className={clsx(classes.label)}>Location *</label>
                <Field
                  as={AddressPickerWithGoogle}
                  fullWidth
                  required
                  name="location"
                  error={touched?.location && errors?.location && true}
                  helperText={touched?.location && errors?.location}
                  // getValues={getGoogleAddress}
                  getValues={(values: Address) => {
                    setGoogleAddress(values);
                    setFieldValue('location', values);
                    setIsDirty(true);
                  }}
                  initialValue={
                    googleAddress ? formatAddress(googleAddress as Address) : ''
                  }
                  setFieldValue={setFieldValue}
                />
              </Grid>

              <Grid item xs={12} style={{ marginBottom: '10px' }}>
                <label className={clsx(classes.label)}>
                  Cost of admission to event *
                </label>

                <Field
                  name="price"
                  type="number"
                  min="0"
                  step="0.01"
                  inputMode="decimal"
                  placeholder="$ Enter Price"
                  className={classes.commonInputStyle}
                  onPaste={preventPasteNegative}
                  onKeyPress={preventMinus}
                  onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                    e.currentTarget.blur()
                  } // Prevent scroll changes
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    let value = e.target.value;
                    if (value.startsWith('.')) {
                      value = '0.';
                    }
                    const decimalIndex = value.indexOf('.');
                    if (decimalIndex !== -1) {
                      value = value.slice(0, decimalIndex + 3);
                    }

                    setFieldValue('price', parseFloat(value));
                  }}
                  onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                    e.target.style.outline = 'none';
                    e.target.style.border = '1px solid #8080807d';
                  }}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    e.target.style.outline = 'none';
                    e.target.style.border = '1px solid #8080807d';
                  }}
                />
                {errors?.price && touched?.price ? (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '14px',
                      padding: '4px 10px',
                      fontFamily: 'Poppins',
                    }}
                  >
                    {errors.price}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={4} md={7} style={{ marginBottom: '10px' }}>
                <label className={clsx(classes.label)}>Photos *</label>
                <div className={clsx(classes.subTitle)}>
                  <ul>
                    <li>The First Image will be the default one.</li>
                    <li>You can drag between the images to rearrange.</li>
                  </ul>
                </div>

                <Box
                  style={{
                    border: '1px dashed #D28B53',
                    borderRadius: '10px',
                    backgroundColor: '#F8F9FF',
                    padding: '20px',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    height: '200px',
                    width: xsDown ? '100%' : '400px',
                  }}
                  onClick={() =>
                    !isLoading &&
                    document.getElementById('photo-upload')?.click()
                  }
                >
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={e => {
                      setIsDirty(true);
                      handleImageUpload(e, setFieldValue);
                    }}
                    style={{ display: 'none' }}
                    id="photo-upload"
                  />
                  {isLoading ? (
                    <CircularProgress style={{ color: '#D28B53' }} />
                  ) : (
                    <RiMailAddFill
                      style={{
                        color: '#D28B53',
                        fontSize: '35px',
                      }}
                    />
                  )}
                </Box>
                {errors.images && touched.images ? (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '14px',
                      padding: '4px 10px',
                      fontFamily: 'Poppins',
                    }}
                  >
                    {errors.images}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} style={{ marginBottom: '10px' }}>
                {selectedImages.length > 0 && (
                  <Grid
                    container
                    spacing={2}
                    className={clsx(classes.scrollContainer)}
                    style={{
                      // border: `1px solid ${theme.palette.primary.main}`,
                      borderRadius: '10px',
                      padding: '10px',
                      marginTop: '20px',

                      height:
                        (isSmallScreen && selectedImages.length > 4) ||
                        (!isSmallScreen && selectedImages.length > 8)
                          ? '300px'
                          : 'auto',
                      overflowY: selectedImages.length > 2 ? 'auto' : 'hidden',
                      // scrollbarWidth: 'thin',
                    }}
                  >
                    <DragDropContext
                      onDragEnd={result =>
                        handleOnDragEnd(result, setFieldValue)
                      }
                    >
                      <Droppable droppableId="images">
                        {provided => (
                          <Grid
                            container
                            spacing={2}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              flexWrap: 'wrap',
                              overflow: 'hidden',
                            }}
                          >
                            {selectedImages?.map((imageObj, index) =>
                              imageObj && imageObj.url ? (
                                <Draggable
                                  key={index}
                                  draggableId={String(index)}
                                  // draggableId={`image-${index}`}
                                  index={index}
                                >
                                  {provided => (
                                    <Grid
                                      item
                                      xs={4}
                                      sm={3}
                                      md={2}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                    >
                                      <div
                                        style={{
                                          position: 'relative',
                                          borderRadius: '16px',
                                          overflow: 'hidden',
                                        }}
                                      >
                                        <img
                                          src={imageObj.url}
                                          alt={`preview-${index}`}
                                          style={{
                                            width: '100%',
                                            height: '124.96px',
                                            objectFit: 'cover',
                                            borderRadius: '13px',
                                            position: 'relative',
                                          }}
                                        />
                                        <CustomButton
                                          onClick={() =>
                                            handleRemoveImage(
                                              index,
                                              setFieldValue,
                                            )
                                          }
                                          style={{
                                            position: 'absolute',
                                            background:
                                              'transparent !important',
                                            top: '0px',
                                            right: '-15px',
                                            borderRadius: '50%',
                                          }}
                                        >
                                          <MdDelete color="#F75946" size={22} />
                                        </CustomButton>
                                      </div>
                                    </Grid>
                                  )}
                                </Draggable>
                              ) : null,
                            )}
                            {provided.placeholder}
                          </Grid>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} style={{ marginBottom: '10px' }}>
                <button
                  type="submit"
                  className={clsx(classes.btn)}
                  disabled={isLoading}
                >
                  {isSaveLoading ? (
                    <CircularProgress size={24} style={{ color: '#fff' }} />
                  ) : (
                    'Save'
                  )}
                </button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Prompt
        when={isDirty}
        message="Data not saved, Are you sure you want to leave ?"
      />
    </Container>
  );
};

export default ListEvent;
