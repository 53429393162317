import { CheckoutData } from '../types/checkoutProcessTypes';

export const setCheckoutData = (data: CheckoutData) => ({
  type: 'SET_CHECKOUT_DATA',
  payload: data,
});

export const setCheckoutLoading = (loading: boolean) => ({
  type: 'SET_CHECKOUT_LOADING',
  payload: loading,
});

export const setCheckoutError = (error: string | null) => ({
  type: 'SET_CHECKOUT_ERROR',
  payload: error,
});
